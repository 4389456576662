import React from "react";
import xlsx from "json-as-xlsx";
import UpdateSimulationStatus, { statusList } from "./update";
import DetailsSimulationsModal from "./view";
import moment from "moment/moment";
import useClientsSimulations from "../../Hooks/useSimulations";
import { useState } from "react";
import {
  extractSpecificObjValueFromFullOBj,
  notExtractSpecificObjValueFromFullOBj,
} from "../../utils/simulation";
import { useEffect } from "react";
import { useModal } from "../../contexts/modal";
import { PageNavigator, SectionLoader } from "../../uikits/others";
import { Form, FormikProvider, useFormik } from "formik";
import { filterSimFields } from "../../utils/form/fields";
import FormikFieldController from "../../uikits/form";
import { FormButton } from "../../uikits/button";
import axios from "axios";

function mapObj(objt) {
  let finalString = "";
  for (const key in objt) {
    finalString += key + ":" + objt[key] + "\n";
  }
  return finalString;
}

let xlsxSettings = {
  fileName: "Simulations",
  extraLength: 3,
  writeMode: "writeFile",
  writeOptions: {},
  RTL: false,
};

const simulationFields = [
  {
    label: "Date de creation",
    value: (row) => moment(row.created_at).format("DD MMM Y H:mm"),
  },
  { label: "Nom", value: (row) => row.nom },
  { label: "Prenom", value: (row) => row.prenom },
  { label: "Mail", value: (row) => row.email },
  { label: "TelePhone", value: (row) => row.phone },
  {
    label: "Simulation Informations ",
    value: (row) =>
      mapObj(
        notExtractSpecificObjValueFromFullOBj(row, [
          "nom",
          "prenom",
          "email",
          "phone",
          "_id",
          "__v",
          "aides",
          "created_at",
        ])
      ),
  },
  {
    label: "Simulation Resultats ",
    value: (row) =>
      mapObj(notExtractSpecificObjValueFromFullOBj(row.aides, ["newAides"])) +
      mapObj(
        extractSpecificObjValueFromFullOBj(row.aides, ["newAides"]).newAides
      ),
  },
  { label: "Occupe a", value: (row) => "..." },
  { label: "Date d'appel ", value: (row) => "..." },
];

function SimulationListFilterAndSearch({ authedInfos }) {
  const { grade, _id } = authedInfos;
  const [filters, setFilters] = useState(null);
  const [dataLimit, setDataLimit] = useState(100);

  const [currentPage, setCurrentPage] = useState(1);

  const { clientSimulations, refetchDatas, similationsLoading } =
    useClientsSimulations(
      dataLimit,
      currentPage,
      filters?.keyWord,
      filters?.from,
      filters?.to,
      filters?.simulationStatus
    );
  const [finalS, setFinalS] = useState([]);

  const { showModal } = useModal();

  useEffect(() => {
    setFinalS(clientSimulations.simulations);
  }, [clientSimulations]);

  useEffect(() => {
    if (currentPage != 1 || filters || dataLimit != 100) {
      refetchDatas();
    }
  }, [currentPage, filters, dataLimit]);

  let xlsData = [
    {
      sheet: "Info simulation client",
      columns: [...simulationFields],
      content: finalS,
    },
  ];

  if (similationsLoading) {
    return <SectionLoader />;
  }

  return (
    <>
      <section className="simulationCountAndAction">
        <b>
          {" "}
          {clientSimulations.totalSimulations} simulations correspondantes{" "}
        </b>
        <section>
          <button
            onClick={() =>
              showModal(
                <SimulationFilter
                  filters={filters}
                  setFilters={setFilters}
                  setDataLimit={setDataLimit}
                />
              )
            }
          >
            Plus de filtre
          </button>
          {authedInfos.grade == "Admin" && (
            <>
              <button
                className="excelBtn"
                onClick={() => xlsx(xlsData, xlsxSettings)}
              >
                Exporter au format xls
              </button>
            </>
          )}
        </section>
      </section>
      <div className="simulationTable">
        <table>
          <thead>
            <tr>
              <th>Nom Client</th>
              <th>Prenom Client</th>
              <th>Mail Client</th>
              <th>Telephone Client</th>
              <th>Statut client</th>
              <th>Fait le</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {finalS.length > 0 ? (
              <>
                {finalS.map((sim, index) => (
                  <tr
                    key={"client sim nb" + index}
                    id={"simulation-" + sim._id}
                  >
                    <td>{sim.nom}</td>
                    <td>{sim.prenom}</td>
                    <td>{sim.email}</td>
                    <td>{sim.phone}</td>
                    <td>
                      {sim.clientStatus
                        ? sim.clientStatus
                        : statusList[0].label}
                    </td>
                    <td>{moment(sim.created_at).format("DD MMM Y H:mm")}</td>
                    <td>
                      <SimulationActionsBtns
                        customprops={{
                          grade,
                          userId: _id,
                          refetchSimulations: refetchDatas,
                          simulation: sim,
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan={5}>Aucune simulation</td>
              </tr>
            )}
          </tbody>
        </table>

        <PageNavigator
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          datas={clientSimulations}
        />
      </div>
    </>
  );
}

function SimulationFilter({ setFilters, filters, setDataLimit }) {
  const { hideModal } = useModal();
  const formik = useFormik({
    initialValues: {
      keyWord: filters?.keyWord ? filters.keyWord : "",
      from: filters?.from ? filters.from : "",
      to: filters?.to ? filters.to : "",
      simulationStatus: filters?.simulationStatus
        ? filters.simulationStatus
        : "",
      dataLimit: 100,
    },
    onSubmit: (values) => {
      let filters = {};
      if (values.keyWord) {
        filters.keyWord = values.keyWord;
      }
      if (values.from) {
        filters.from = values.from;
      }
      if (values.from && values.to) {
        filters.to = values.to;
      }
      if (values.simulationStatus) {
        filters.simulationStatus = values.simulationStatus;
      }
      if (values.dataLimit > 0 && values.dataLimit != 100) {
        setDataLimit(values.dataLimit);
      }
      setFilters(filters);
      hideModal();
    },
  });
  const { values } = formik;
  return (
    <FormikProvider value={formik}>
      <Form>
        {filterSimFields(statusList).map((item, i) => (
          <FormikFieldController {...item} key={"sim filter field nb" + i} />
        ))}
        <FormButton text="Filtrer" isValid={true} />
      </Form>
    </FormikProvider>
  );
}

function SimulationActionsBtns({ customprops }) {
  const { grade, userId, simulation, refetchSimulations } = customprops;
  const { showModal } = useModal();

  function deleteSimulation() {
    axios
      .delete("/admin/simulation/delete/" + simulation._id)
      .then((res) => null)
      .catch((err) => null)
      .finally((res) => refetchSimulations());
  }

  return (
    <div className="manyTableBtns">
      <button
        className="viewBtn"
        onClick={() => {
          showModal(<DetailsSimulationsModal simulation={simulation} />);
        }}
      >
        Voir
      </button>
      {grade == "Commercial" && simulation.agentId == userId && (
        <button
          className="updateBtn"
          onClick={() => {
            showModal(
              <UpdateSimulationStatus
                simulation={simulation}
                refetchSimulations={refetchSimulations}
              />
            );
          }}
        >
          Changer le statut
        </button>
      )}
      <button onClick={deleteSimulation}>Supprimer</button>
    </div>
  );
}
export default SimulationListFilterAndSearch;
